import styled from 'styled-components';
import {Modal} from "react-bootstrap";


const StyledModal = styled(Modal)`
.modal-content{
  border:none !important;
}
  .modal-dialog {
    width: 550px;
    border-radius: 6px;
    background: white;
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    /* .modal-content {
      padding: 25px;
      height: 507px;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    } */
    .close {
      margin-top: -30px;
    }
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;

const TipsButton = styled.button`
  width: 65%;
  height: 50px;
  outline: none;
  border: none;
  margin: 20px 0px;
  background-color: #69c2ff;
  border-radius: 6px;
  color: #FFFFFF;
  font-family: 'Rubik-Medium';
  font-size: 18px;
`;

export {StyledModal, TipsButton}